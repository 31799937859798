define("ember-cli-notifications/templates/components/ecn-icon-success", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <svg ...attributes aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
  */
  {
    "id": "UuS3qP1K",
    "block": "[[[11,\"svg\"],[17,1],[24,\"aria-hidden\",\"true\"],[24,\"focusable\",\"false\"],[24,\"role\",\"img\"],[24,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[24,\"viewBox\",\"0 0 512 512\"],[24,\"width\",\"16\"],[24,\"height\",\"16\"],[12],[10,\"path\"],[14,\"fill\",\"currentColor\"],[14,\"d\",\"M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z\"],[12],[13],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "ember-cli-notifications/templates/components/ecn-icon-success.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});