define("@1024pix/pix-ui/translations/index", ["exports", "@formatjs/intl", "@1024pix/pix-ui/translations/en", "@1024pix/pix-ui/translations/fr"], function (_exports, _intl, _en, _fr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flattenObject = flattenObject;
  _exports.formatMessage = formatMessage;
  function formatMessage(locale, message, values) {
    return locales[locale].formatMessage({
      id: message
    }, values);
  }
  const locales = {
    fr: (0, _intl.createIntl)({
      locale: 'fr',
      messages: flattenObject(_fr.default)
    }),
    en: (0, _intl.createIntl)({
      locale: 'en',
      messages: flattenObject(_en.default)
    })
  };
  function flattenObject(object) {
    const entries = Object.entries(object);
    const flatEntries = entries.flatMap(_ref => {
      let [key, value] = _ref;
      if (typeof value !== 'object') return [[key, value]];
      const childEntries = Object.entries(flattenObject(value));
      return childEntries.map(_ref2 => {
        let [childKey, childValue] = _ref2;
        return [`${key}.${childKey}`, childValue];
      });
    });
    return Object.fromEntries(flatEntries);
  }
});