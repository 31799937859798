define("@1024pix/pix-ui/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    pagination: {
      beforeResultsPerPage: 'See',
      selectPageSizeLabel: 'Select the number of items by page',
      pageResults: '{total, plural, =0 {0 items} =1 {1 item} other {{total, number} items}}',
      pageInfo: '{start, number}-{end, number} of {total, plural, =0 {0 items} =1 {1 item} other {{total, number} items}}',
      previousPageLabel: 'Go to previous page',
      pageNumber: 'Page {current, number} / {total, number}',
      nextPageLabel: 'Go to next page'
    }
  };
  _exports.default = _default;
});