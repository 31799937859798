define("@1024pix/pix-ui/components/pix-collapsible", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/internals"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pix-collapsible {{if this.isUnCollapsed 'pix-collapsible--uncollapsed'}}">
  
    <button
      type="button"
      {{on "click" this.toggleCollapsible}}
      class="pix-collapsible__title {{if this.isUnCollapsed 'pix-collapsible__title--uncollapsed'}}"
      aria-controls={{this.contentId}}
      aria-expanded={{if this.isUnCollapsed "true" "false"}}
      ...attributes
    >
      <span class="pix-collapsible-title__container">
        {{#if @titleIcon}}
          <FaIcon @icon={{@titleIcon}} class="pix-collapsible-title__icon" />
        {{/if}}
  
        {{#if (has-block "title")}}
          {{yield to="title"}}
        {{else}}
          {{this.title}}
        {{/if}}
      </span>
  
      <FaIcon
        @icon="{{if this.isCollapsed 'plus' 'minus'}}"
        class="pix-collapsible-title__toggle-icon"
      />
    </button>
  
    <div
      id={{this.contentId}}
      aria-hidden={{if this.isCollapsed "true" "false"}}
      class="pix-collapsible__content
        {{if this.isUnCollapsed ' pix-collapsible__content--uncollapse'}}"
    >
      {{#if this.isContentRendered}}
        {{yield}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "SA9OyuMM",
    "block": "[[[10,0],[15,0,[29,[\"pix-collapsible \",[52,[30,0,[\"isUnCollapsed\"]],\"pix-collapsible--uncollapsed\"]]]],[12],[1,\"\\n\\n  \"],[11,\"button\"],[24,4,\"button\"],[16,0,[29,[\"pix-collapsible__title \",[52,[30,0,[\"isUnCollapsed\"]],\"pix-collapsible__title--uncollapsed\"]]]],[16,\"aria-controls\",[30,0,[\"contentId\"]]],[16,\"aria-expanded\",[52,[30,0,[\"isUnCollapsed\"]],\"true\",\"false\"]],[17,1],[4,[38,1],[\"click\",[30,0,[\"toggleCollapsible\"]]],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"pix-collapsible-title__container\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[8,[39,2],[[24,0,\"pix-collapsible-title__icon\"]],[[\"@icon\"],[[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"        \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,0,[\"title\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[8,[39,2],[[24,0,\"pix-collapsible-title__toggle-icon\"]],[[\"@icon\"],[[29,[[52,[30,0,[\"isCollapsed\"]],\"plus\",\"minus\"]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[15,1,[30,0,[\"contentId\"]]],[15,\"aria-hidden\",[52,[30,0,[\"isCollapsed\"]],\"true\",\"false\"]],[15,0,[29,[\"pix-collapsible__content\\n      \",[52,[30,0,[\"isUnCollapsed\"]],\" pix-collapsible__content--uncollapse\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"isContentRendered\"]],[[[1,\"      \"],[18,4,null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@titleIcon\",\"&title\",\"&default\"],false,[\"if\",\"on\",\"fa-icon\",\"has-block\",\"yield\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-collapsible.hbs",
    "isStrictMode": false
  });
  let PixCollapsible = (_class = class PixCollapsible extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "text", 'pix-collapsible');
      _defineProperty(this, "contentId", 'pix-collapsible-' + (0, _internals.guidFor)(this));
      _initializerDefineProperty(this, "isCollapsed", _descriptor, this);
      _initializerDefineProperty(this, "hasUnCollapsedOnce", _descriptor2, this);
    }
    get isUnCollapsed() {
      return !this.isCollapsed;
    }
    get isContentRendered() {
      return this.hasUnCollapsedOnce;
    }
    get title() {
      if (!this.args.title || !this.args.title.trim()) {
        throw new Error('ERROR in PixCollapsible component, @title param is not provided');
      }
      return this.args.title;
    }
    toggleCollapsible() {
      this.isCollapsed = !this.isCollapsed;
      this.hasUnCollapsedOnce = true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isCollapsed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasUnCollapsedOnce", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleCollapsible", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCollapsible"), _class.prototype)), _class);
  _exports.default = PixCollapsible;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixCollapsible);
});