define("@1024pix/pix-ui/components/pix-block", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pix-block {{concat 'pix-block--shadow-' this.getShadowWeight}}" ...attributes>
  
    {{yield}}
  
  </div>
  */
  {
    "id": "wX7tZx+Y",
    "block": "[[[11,0],[16,0,[29,[\"pix-block \",[28,[37,0],[\"pix-block--shadow-\",[30,0,[\"getShadowWeight\"]]],null]]]],[17,1],[12],[1,\"\\n\\n  \"],[18,2,null],[1,\"\\n\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"concat\",\"yield\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-block.hbs",
    "isStrictMode": false
  });
  class PixBlockComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "text", 'pix-block');
    }
    get getShadowWeight() {
      const shadowParam = this.args.shadow;
      const correctsWeight = ['light', 'heavy'];
      return correctsWeight.includes(shadowParam) ? shadowParam : 'light';
    }
  }
  _exports.default = PixBlockComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixBlockComponent);
});