define("ember-api-actions/utils/build-url", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._getModelClass = _getModelClass;
  _exports._getModelName = _getModelName;
  _exports._getStoreFromRecord = _getStoreFromRecord;
  _exports.buildOperationUrl = buildOperationUrl;
  _exports.default = void 0;
  /**
   * Given a record, obtain the ember-data model class
   * @param record
   */
  function _getModelClass(record) {
    return record.constructor;
  }

  /**
   * Given an ember-data model class, obtain its name
   * @param clazz
   */
  function _getModelName(clazz) {
    return (
      // prettier-ignore
      clazz.modelName // modern use
      // @ts-ignore
      || clazz.typeKey // legacy fallback
    );
  }

  /**
   * Given an ember-data-record, obtain the related Store
   * @param record
   */
  function _getStoreFromRecord(record) {
    const owner = (0, _application.getOwner)(record);
    return owner.lookup('service:store');
  }
  function snapshotFromRecord(model) {
    return model._createSnapshot();
  }

  /**
   *
   * @param record
   * @param opPath
   * @param urlType
   * @param instance
   */
  function buildOperationUrl(record, opPath, urlType) {
    let instance = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    const modelClass = _getModelClass(record);
    const modelName = _getModelName(modelClass);
    const store = _getStoreFromRecord(record);
    const adapter = store.adapterFor(modelName);
    const path = opPath;
    const snapshot = snapshotFromRecord(record);
    const baseUrl = adapter.buildURL(modelName, instance ? record.get('id') : null, snapshot, urlType);
    if (!path) {
      return baseUrl;
    }
    if (baseUrl.charAt(baseUrl.length - 1) === '/') {
      return `${baseUrl}${path}`;
    } else {
      return `${baseUrl}/${path}`;
    }
  }
  var _default = buildOperationUrl;
  _exports.default = _default;
});