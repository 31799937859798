define("@1024pix/pix-ui/components/pix-button-link", ["exports", "@ember/component", "@ember/template-factory", "@1024pix/pix-ui/components/pix-button-base"], function (_exports, _component, _templateFactory, _pixButtonBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @route}}
    <LinkTo
      @route={{@route}}
      @models={{if @model (array @model) this.defaultModel}}
      @disabled={{@isDisabled}}
      @query={{if @query @query this.defaultParams}}
      class={{this.className}}
      ...attributes
    >
      {{yield}}
    </LinkTo>
  {{else}}
    <a href={{@href}} class={{this.className}} ...attributes>
      {{yield}}
    </a>
  {{/if}}
  */
  {
    "id": "XjPoFTxa",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[16,0,[30,0,[\"className\"]]],[17,2]],[[\"@route\",\"@models\",\"@disabled\",\"@query\"],[[30,1],[52,[30,3],[28,[37,2],[[30,3]],null],[30,0,[\"defaultModel\"]]],[30,4],[52,[30,5],[30,5],[30,0,[\"defaultParams\"]]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,7,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,3],[16,6,[30,6]],[16,0,[30,0,[\"className\"]]],[17,2],[12],[1,\"\\n    \"],[18,7,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@route\",\"&attrs\",\"@model\",\"@isDisabled\",\"@query\",\"@href\",\"&default\"],false,[\"if\",\"link-to\",\"array\",\"yield\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-button-link.hbs",
    "isStrictMode": false
  });
  class PixButtonLink extends _pixButtonBase.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "defaultModel", []);
      _defineProperty(this, "defaultParams", {});
    }
    get className() {
      return super.baseClassNames.join(' ');
    }
  }
  _exports.default = PixButtonLink;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixButtonLink);
});