define("@1024pix/pix-ui/translations/fr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    pagination: {
      beforeResultsPerPage: 'Voir',
      selectPageSizeLabel: "Nombre d'élément à afficher par page",
      pageResults: '{total, plural, =0 {0 élément} =1 {1 élément} other {{total, number} éléments}}',
      pageInfo: '{start, number}-{end, number} sur {total, plural, =0 {0 élément} =1 {1 élément} other {{total, number} éléments}}',
      previousPageLabel: 'Aller à la page précédente',
      pageNumber: 'Page {current, number} / {total, number}',
      nextPageLabel: 'Aller à la page suivante'
    }
  };
  _exports.default = _default;
});