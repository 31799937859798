define("@1024pix/pix-ui/components/pix-button-upload", ["exports", "@ember/component", "@ember/template-factory", "@1024pix/pix-ui/components/pix-button-base", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _pixButtonBase, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label for={{@id}} class={{this.className}} role="button">
    {{yield}}
  </label>
  <input
    id={{@id}}
    type="file"
    class="pix-button-upload__input"
    value={{this.files}}
    {{on "change" this.handleChange}}
    ...attributes
  />
  */
  {
    "id": "zY+bm/oa",
    "block": "[[[10,\"label\"],[15,\"for\",[30,1]],[15,0,[30,0,[\"className\"]]],[14,\"role\",\"button\"],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13],[1,\"\\n\"],[11,\"input\"],[16,1,[30,1]],[24,4,\"file\"],[24,0,\"pix-button-upload__input\"],[16,2,[30,0,[\"files\"]]],[17,2],[4,[38,1],[\"change\",[30,0,[\"handleChange\"]]],null],[12],[13]],[\"@id\",\"&attrs\",\"&default\"],false,[\"yield\",\"on\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-button-upload.hbs",
    "isStrictMode": false
  });
  let PixButtonUpload = (_class = class PixButtonUpload extends _pixButtonBase.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "files", _descriptor, this);
    }
    get className() {
      return super.baseClassNames.join(' ');
    }
    async handleChange(e) {
      var _e$target, _e$target$files;
      if ((_e$target = e.target) !== null && _e$target !== void 0 && (_e$target$files = _e$target.files) !== null && _e$target$files !== void 0 && _e$target$files.length) {
        await this.args.onChange(e.target.files);
      }
      this.files = [];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "files", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class);
  _exports.default = PixButtonUpload;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixButtonUpload);
});