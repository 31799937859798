define("ember-cli-notifications/components/ecn-icon-info", ["exports", "@ember/component", "ember-cli-notifications/templates/components/ecn-icon-info"], function (_exports, _component, _ecnIconInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class EmberCliNotificationsIconInfoComponent extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layout", _ecnIconInfo.default);
      _defineProperty(this, "tagName", '');
    }
  }
  _exports.default = EmberCliNotificationsIconInfoComponent;
});