define("@1024pix/pix-ui/components/pix-input", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pix-input">
    {{#if @label}}
      <label for={{this.id}} class="pix-input__label">
        {{#if @requiredLabel}}
          <abbr title={{@requiredLabel}} class="mandatory-mark" aria-hidden="true">*</abbr>
        {{/if}}
  
        {{@label}}
  
        {{#if @information}}
          <span class="pix-input__information">{{@information}}</span>
        {{/if}}
      </label>
    {{/if}}
  
    <div class="pix-input__container">
      <input
        id={{this.id}}
        class={{if @errorMessage "pix-input__input--error"}}
        value={{@value}}
        aria-label={{this.ariaLabel}}
        aria-required="{{if @requiredLabel true false}}"
        required={{if @requiredLabel true false}}
        aria-describedby={{if @errorMessage "text-input-error"}}
        ...attributes
      />
  
      {{#if @errorMessage}}
        <FaIcon @icon="xmark" class="pix-input__error-icon" />
      {{/if}}
    </div>
  
    {{#if @errorMessage}}
      <p id="text-input-error" class="pix-input__error-message">{{@errorMessage}}</p>
    {{/if}}
  </div>
  */
  {
    "id": "GvEs+Ca6",
    "block": "[[[10,0],[14,0,\"pix-input\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,\"label\"],[15,\"for\",[30,0,[\"id\"]]],[14,0,\"pix-input__label\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[10,\"abbr\"],[15,\"title\",[30,2]],[14,0,\"mandatory-mark\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"*\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"        \"],[10,1],[14,0,\"pix-input__information\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"pix-input__container\"],[12],[1,\"\\n    \"],[11,\"input\"],[16,1,[30,0,[\"id\"]]],[16,0,[52,[30,4],\"pix-input__input--error\"]],[16,2,[30,5]],[16,\"aria-label\",[30,0,[\"ariaLabel\"]]],[16,\"aria-required\",[29,[[52,[30,2],true,false]]]],[16,\"required\",[52,[30,2],true,false]],[16,\"aria-describedby\",[52,[30,4],\"text-input-error\"]],[17,6],[12],[13],[1,\"\\n\\n\"],[41,[30,4],[[[1,\"      \"],[8,[39,1],[[24,0,\"pix-input__error-icon\"]],[[\"@icon\"],[\"xmark\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n\"],[41,[30,4],[[[1,\"    \"],[10,2],[14,1,\"text-input-error\"],[14,0,\"pix-input__error-message\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[13]],[\"@label\",\"@requiredLabel\",\"@information\",\"@errorMessage\",\"@value\",\"&attrs\"],false,[\"if\",\"fa-icon\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-input.hbs",
    "isStrictMode": false
  });
  const ERROR_MESSAGE = 'ERROR in PixInput component, you must provide @label or @ariaLabel params';
  class PixInput extends _component2.default {
    get id() {
      if (!this.args.id || !this.args.id.toString().trim()) {
        throw new Error('ERROR in PixInput component, @id param is not provided');
      }
      return this.args.id;
    }
    get label() {
      if (!this.args.label && !this.args.ariaLabel) {
        throw new Error(ERROR_MESSAGE);
      }
      return this.args.label;
    }
    get ariaLabel() {
      if (!this.args.label && !this.args.ariaLabel) {
        throw new Error(ERROR_MESSAGE);
      }
      return this.args.label ? null : this.args.ariaLabel;
    }
  }
  _exports.default = PixInput;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixInput);
});