define("ember-click-outside/component", ["exports", "@ember/component", "@ember/runloop", "ember-click-outside/utils", "@ember/object"], function (_exports, _component, _runloop, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-component-lifecycle-hooks */
  /* eslint-disable ember/require-tagless-components */
  /* eslint-disable ember/no-classic-classes */
  /* eslint-disable ember/no-classic-components */

  const bound = function (fnName) {
    return (0, _object.computed)(fnName, function () {
      let fn = (0, _object.get)(this, fnName);
      if (fn) {
        // https://github.com/zeppelin/ember-click-outside/issues/1
        return fn.bind(this);
      }
      return;
    });
  };
  var _default = _component.default.extend({
    clickHandler: bound('outsideClickHandler'),
    outsideClickHandler(e) {
      const element = this.element;
      const path = e.path || e.composedPath && e.composedPath();
      if (path) {
        path.includes(element) || this.clickOutside(e);
      } else {
        // Check if the click target still is in the DOM.
        // If not, there is no way to know if it was inside the element or not.
        const isRemoved = !e.target || !(0, _utils.documentOrBodyContains)(e.target);

        // Check the element is found as a parent of the click target.
        const isInside = element === e.target || element.contains(e.target);
        if (!isRemoved && !isInside) {
          this.clickOutside(e);
        }
      }
    },
    didInsertElement() {
      this._super(...arguments);
      this._cancelOutsideListenerSetup = (0, _runloop.next)(this, this.addClickOutsideListener);
      if (!(0, _utils.ios)()) {
        return;
      }
      document.body.style.cursor = 'pointer';
    },
    willDestroyElement() {
      this._super(...arguments);
      (0, _runloop.cancel)(this._cancelOutsideListenerSetup);
      this.removeClickOutsideListener();
      if (!(0, _utils.ios)()) {
        return;
      }
      document.body.style.cursor = '';
    },
    addClickOutsideListener() {
      const eventType = this.eventType || 'click';
      const clickHandler = this.clickHandler;
      document.addEventListener(eventType, clickHandler);
    },
    removeClickOutsideListener() {
      const eventType = this.eventType || 'click';
      const clickHandler = this.clickHandler;
      document.removeEventListener(eventType, clickHandler);
    },
    clickOutside(e) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      const exceptSelector = this.exceptSelector;
      if (exceptSelector && (0, _utils.closest)(e.target, exceptSelector)) {
        return;
      }
      let onClickOutside = this.onClickOutside;
      if (typeof onClickOutside === 'function') {
        onClickOutside(e);
      }
    }
  });
  _exports.default = _default;
});