define("@1024pix/pix-ui/components/pix-tooltip", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span
    class="pix-tooltip"
    {{on-escape-action this.hideTooltip}}
    {{on "mouseover" this.showTooltip}}
    {{on "mouseleave" this.hideTooltipOnMouseOut}}
    {{on "focusin" this.showTooltip}}
    {{on "focusout" this.hideTooltip}}
    ...attributes
  >
    {{#if (has-block "triggerElement")}}
      <span class="pix-tooltip__trigger-element">
        {{yield to="triggerElement"}}
      </span>
    {{/if}}
  
    {{#if (has-block "tooltip")}}
      {{#if this.display}}
        <span
          id={{@id}}
          role="tooltip"
          class="pix-tooltip__content pix-tooltip__content--{{this.position}}
            {{if this.isVisible 'pix-tooltip__content--visible'}}
            {{if @isInline 'pix-tooltip__content--inline'}}
            {{if @isLight 'pix-tooltip__content--light'}}
            {{if @isWide 'pix-tooltip__content--wide'}}"
        >
          {{yield to="tooltip"}}
        </span>
      {{/if}}
    {{/if}}
  </span>
  */
  {
    "id": "riySgw4G",
    "block": "[[[11,1],[24,0,\"pix-tooltip\"],[17,1],[4,[38,0],[[30,0,[\"hideTooltip\"]]],null],[4,[38,1],[\"mouseover\",[30,0,[\"showTooltip\"]]],null],[4,[38,1],[\"mouseleave\",[30,0,[\"hideTooltipOnMouseOut\"]]],null],[4,[38,1],[\"focusin\",[30,0,[\"showTooltip\"]]],null],[4,[38,1],[\"focusout\",[30,0,[\"hideTooltip\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"    \"],[10,1],[14,0,\"pix-tooltip__trigger-element\"],[12],[1,\"\\n      \"],[18,6,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,7]],[[[41,[30,0,[\"display\"]],[[[1,\"      \"],[10,1],[15,1,[30,2]],[14,\"role\",\"tooltip\"],[15,0,[29,[\"pix-tooltip__content pix-tooltip__content--\",[30,0,[\"position\"]],\"\\n          \",[52,[30,0,[\"isVisible\"]],\"pix-tooltip__content--visible\"],\"\\n          \",[52,[30,3],\"pix-tooltip__content--inline\"],\"\\n          \",[52,[30,4],\"pix-tooltip__content--light\"],\"\\n          \",[52,[30,5],\"pix-tooltip__content--wide\"]]]],[12],[1,\"\\n        \"],[18,7,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[13]],[\"&attrs\",\"@id\",\"@isInline\",\"@isLight\",\"@isWide\",\"&triggerElement\",\"&tooltip\"],false,[\"on-escape-action\",\"on\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-tooltip.hbs",
    "isStrictMode": false
  });
  let PixTooltip = (_class = class PixTooltip extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isVisible", _descriptor, this);
    }
    get position() {
      const correctsPosition = ['top', 'right', 'bottom', 'bottom-left', 'bottom-right', 'left', 'top-left', 'top-right'];
      return correctsPosition.includes(this.args.position) ? this.args.position : 'top';
    }
    get display() {
      return typeof this.args.hide === 'undefined' || !this.args.hide;
    }
    showTooltip() {
      this.isVisible = true;
    }
    hideTooltip() {
      this.isVisible = false;
    }
    hideTooltipOnMouseOut(event) {
      const isFocused = event.target.contains(document.activeElement);
      if (isFocused) {
        return;
      }
      this.hideTooltip(event);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showTooltip", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showTooltip"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideTooltip", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hideTooltip"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideTooltipOnMouseOut", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hideTooltipOnMouseOut"), _class.prototype)), _class);
  _exports.default = PixTooltip;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixTooltip);
});